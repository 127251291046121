import React from "react";
import "./News.css"

const News = () => (
    <div className="responsive-text">
        This
        <span className="responsive-highlight-green">&nbsp;Page&nbsp;</span>
        Is Under
        <span className="responsive-highlight-blue">&nbsp;Construction&nbsp;</span>
    </div>
);

export default News;
