import React from "react";
import "./ServiceCards.css";
import ServiceCard from "./ServiceCard";
import cardImg1 from "../../assets/services_gif-1.gif";
import cardImg2 from "../../assets/services_gif-2.gif";
import bgCardImg from "../../assets/arcapay_service.png";
import { useLanguage } from "../../LanguageContext";

function ServiceCards() {
    const { language, specializedText } = useLanguage();

    const dynamicStyles = {
        fontFamily: language === "ARM" && "Mardoto",
        fontWeight: language === "ARM" && 500,
    };

    const isArmenian = language === "ARM";

    const serviceDetails1 = [
        {
            header: "ArcaPay",
            title1: [specializedText[language]?.[0]?.title1P1, specializedText[language]?.[0]?.title2P1, specializedText[language]?.[0]?.title3P1],
            title2: [specializedText[language]?.[0]?.title1P2, specializedText[language]?.[0]?.title2P2, specializedText[language]?.[0]?.title3P2],
            titleText: [specializedText[language]?.[0]?.text1, specializedText[language]?.[0]?.text2, specializedText[language]?.[0]?.text3],
            description: specializedText[language]?.[0]?.specialText,
            bgImage: `${bgCardImg}`,
        },
    ];

    const serviceDetails2 = [
        {
            header: specializedText[language]?.[1]?.category,
            title1: [specializedText[language]?.[1]?.title1P1, specializedText[language]?.[1]?.title2P1, specializedText[language]?.[1]?.title3P1],
            title2: [specializedText[language]?.[1]?.title1P2, specializedText[language]?.[1]?.title2P2, specializedText[language]?.[1]?.title3P2],
            titleText: [specializedText[language]?.[1]?.text1, specializedText[language]?.[1]?.text2, specializedText[language]?.[1]?.text3],
            description: specializedText[language]?.[1]?.specialText,
            image: `${cardImg1}`,
        },
    ];

    const serviceDetails3 = [
        {
            header: specializedText[language]?.[2]?.category,
            title1: [specializedText[language]?.[2]?.title1P1, specializedText[language]?.[2]?.title2P1, specializedText[language]?.[2]?.title3P1],
            title2: [specializedText[language]?.[2]?.title1P2, specializedText[language]?.[2]?.title2P2, specializedText[language]?.[2]?.title3P2],
            titleText: [specializedText[language]?.[2]?.text1, specializedText[language]?.[2]?.text2, specializedText[language]?.[2]?.text3],
            description: specializedText[language]?.[2]?.specialText,
            image: `${cardImg2}`,
        },
    ];

    return (
        <div className={`service-cards_container ${isArmenian && "arm-language"}`}>
            <div className="service-cards">
                <div className="service-cards-header">
                    <span style={dynamicStyles}>{specializedText[language][0].specialTitle}</span>
                    <div className="service-cards-header_icons">
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.0164407 3.82196L0 12L3.99046 12L3.99046 3.99715C4.00629 3.99715 4.9794 4.00325 4.9794 4.0002L12 4.0002L12 -5.24537e-07L3.83761 -1.67747e-07C1.73064 -7.56487e-08 0.0219223 1.70984 0.0176598 3.82196"
                                fill="#243BE2"
                            />
                        </svg>
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M8.17804 11.9836L0 12L0 8.00954L8.00285 8.00954C8.00285 7.99371 7.99675 7.0206 7.9998 7.0206L7.9998 0H12L12 8.16239C12 10.2694 10.2902 11.9781 8.17804 11.9823"
                                fill="#ADF233"
                            />
                        </svg>
                    </div>
                </div>
                <div className="service-cards-title">
                    <h1 style={dynamicStyles}>{specializedText[language][0].specialSubTitle}</h1>
                </div>
            </div>

            {serviceDetails1.map((service, index) => (
                <ServiceCard
                    key={index}
                    id={1}
                    header={service.header}
                    title1={service.title1}
                    title2={service.title2}
                    titleText={service.titleText}
                    description={service.description}
                    bgImage={service.bgImage}
                />
            ))}
            {serviceDetails2.map((service, index) => (
                <ServiceCard
                    key={index}
                    id={2}
                    header={service.header}
                    title1={service.title1}
                    title2={service.title2}
                    titleText={service.titleText}
                    description={service.description}
                    image={service.image}
                />
            ))}
            {serviceDetails3.map((service, index) => (
                <ServiceCard
                    key={index}
                    id={3}
                    header={service.header}
                    title1={service.title1}
                    title2={service.title2}
                    titleText={service.titleText}
                    description={service.description}
                    image={service.image}
                />
            ))}
        </div>
    );
}

export default ServiceCards;
