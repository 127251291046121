import React, { useState, useEffect } from "react";
import { useLanguage } from "../LanguageContext";
import MainBlock from "../components/MainBlock";
import Footer from "../components/Footer";
import Wrapper from "../components/Wrapper";
import Payment from "../components/services/Payment";
import ServiceCards from "../components/services/ServiceCards";
import bgGif from "../assets/bg.gif";
import LinkCard from "../components/LinkCard";
import "./Services.css";
//link card images
import linkBg1 from "../assets/link-card_bg1.png";
import linkBg2 from "../assets/link-card_bg2.png";
import linkBg3 from "../assets/link-card_bg3.png";
import { useLocation } from "react-router-dom";

const Services = () => {
    const { language, heroText, linkCardsContent } = useLanguage();

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 576);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const paymentTitle = isMobile ? heroText[language].servicesMobile : heroText[language].services;


    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const element = document.querySelector(location.hash);
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        }
    }, [location]);

    return (
        <>
            <MainBlock title={paymentTitle} gifSrc={bgGif} />
            <Wrapper>
                <Payment />
                <ServiceCards />
                <div className="linkcards-container">
                    <LinkCard
                        linkPath="/about"
                        title={linkCardsContent[language].aboutus[0].name}
                        titleText={linkCardsContent[language].aboutus[1].title}
                        description={linkCardsContent[language].aboutus[2].desc}
                        bgImg={linkBg1}
                    />
                    <LinkCard
                        linkPath="/careers"
                        title={linkCardsContent[language].career[0].name}
                        titleText={linkCardsContent[language].career[1].title}
                        description={linkCardsContent[language].career[2].desc}
                        bgImg={linkBg2}
                    />
                    <LinkCard
                        linkPath="/news"
                        title={linkCardsContent[language].news[0].name}
                        titleText={linkCardsContent[language].news[1].title}
                        description={linkCardsContent[language].news[2].desc}
                        bgImg={linkBg3}
                    />
                </div>
                <Footer />
            </Wrapper>
        </>
    );
};

export default Services;
