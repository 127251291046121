import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import { useLanguage } from "../LanguageContext";

function Footer() {
    const { language, textContent, footerText } = useLanguage();

    const dynamicStyles = {
        fontFamily: language === "ARM" && "Mardoto",
        fontWeight: language === "ARM" && 500,
    };

    return (
        <div className={`footer ${language === "ARM" ? "arm-language" : ""}`}>
            <div className="footer-description">
                <span>Arca</span>
                <p style={dynamicStyles}>{footerText[language][0].description}</p>
            </div>
            <div className="footer-container">
                <div className="footer-links">
                    <div className="footer-link">
                        <span style={dynamicStyles}>{textContent[language].links}</span>
                        <div className="footer-link_items">
                            <Link to="/about" style={dynamicStyles}>
                                {" "}
                                {textContent[language].about}
                            </Link>
                            <Link to="/services" style={dynamicStyles}>
                                {" "}
                                {textContent[language].services}
                            </Link>
                            <Link to="/news" style={dynamicStyles}>
                                {" "}
                                {textContent[language].news}
                            </Link>
                            <Link to="/careers" style={dynamicStyles}>
                                {" "}
                                {textContent[language].careers}
                            </Link>
                            <Link to="/contacts" style={dynamicStyles}>
                                {" "}
                                {textContent[language].contacts}
                            </Link>
                            <Link to="/documents" style={dynamicStyles}>
                                {" "}
                                {textContent[language].documents}
                            </Link>
                        </div>
                    </div>
                    <div className="footer-link footer-link-mobile">
                        <span style={dynamicStyles}>{footerText[language][3].social}</span>
                        <div className="footer-link_items">
                            <a href="https://www.facebook.com/armeniancard" target="_blank" rel="noreferrer">
                                Facebook
                            </a>
                            <a href="https://www.linkedin.com/company/1460902" target="_blank" rel="noreferrer">
                                LinkedIn
                            </a>
                            <a href="https://www.instagram.com/arca.armenia" target="_blank" rel="noreferrer">
                                Instagram
                            </a>
                        </div>
                    </div>
                    <div className="footer-link">
                        <span style={dynamicStyles}>{footerText[language][1].address}</span>
                        <div className="footer-link_items">
                            <a style={dynamicStyles} href="https://maps.app.goo.gl/G8cARCiXGU4qcLNy8" target="_blank" rel="noreferrer">
                                {footerText[language][1].address1}
                            </a>
                            <a style={dynamicStyles} href="https://maps.app.goo.gl/kN5bG1M8gVUXzygXA" target="_blank" rel="noreferrer">
                                {footerText[language][1].address2}
                            </a>
                        </div>
                    </div>
                    <div className="footer-link">
                        <span style={dynamicStyles}>{footerText[language][2].contact}</span>
                        <div className="footer-link_items">
                            <a href="tel:+37410592222" style={dynamicStyles}>
                                {footerText[language][2].phoneNumber}
                            </a>
                            {/* <a href="tel:+37410440390">+374 10 44 03 90</a> */}
                            <a href="mailto:info@arca.am" style={dynamicStyles}>
                                {footerText[language][2].mail}
                            </a>
                        </div>
                    </div>
                    <div className="footer-link footer-link-desktop">
                        <span style={dynamicStyles}>{footerText[language][3].social}</span>
                        <div className="footer-link_items">
                            <a href="https://www.facebook.com/armeniancard" target="_blank" rel="noreferrer">
                                Facebook
                            </a>
                            <a href="https://www.linkedin.com/company/1460902" target="_blank" rel="noreferrer">
                                LinkedIn
                            </a>
                            {/* <a href="https://wa.me/yourwhatsapplink" target="_blank" rel="noreferrer">
                                WhatsApp
                            </a> */}
                            <a href="https://www.instagram.com/arca.armenia" target="_blank" rel="noreferrer">
                                Instagram
                            </a>
                        </div>
                    </div>
                </div>
                <div className="footer_credentials-container">
                    <div className="footer-credentials">
                        <span style={dynamicStyles}>{footerText[language][4].test}</span>
                        <div className="footer-privacy">
                            <Link to="/privacy-policy" style={dynamicStyles}>
                                {footerText[language][5].privacy}
                            </Link>
                            <Link to="/terms-conditions" style={dynamicStyles}>
                                {footerText[language][5].terms}
                            </Link>
                        </div>
                    </div>
                    <span className="bygaroon">
                        Design & Development By{" "}
                        <a href="https://garoon.design/" target="_blank">
                            GSCA
                        </a>
                    </span>
                </div>
            </div>
        </div>
    );
}

export default Footer;
