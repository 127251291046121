import React from "react";
import { Link } from "react-router-dom";
import "./News.css";
import newsImage1 from "../../assets/news_1.png";
import newsImage2 from "../../assets/news_2.png";
import newsImage3 from "../../assets/news_3.png";
import newsImage4 from "../../assets/news_4.png";

const newsData = [
    {
        date: "26 June, 2024",
        title: "Vardan Khachatryan Appointed as CEO of Armenian Card",
        description: "Ardshinbank was the first in Armenia to start issuing chip payment cards Arca-MIR, which...",
        imageUrl: newsImage1,
    },
    {
        date: "15 June, 2024",
        title: "The old version of the Arca website will operate until July 12, 2019",
        description: "It's been already two months since our new site https://cabinet.arca.am and Arca.am...",
        imageUrl: newsImage2,
    },
    {
        date: "24 June, 2024",
        title: "Armenian Card prepares to launch updated website and application",
        description: "In an exclusive interview with ARKA news agency Ishkhan Mkhitaryan, the executive...",
        imageUrl: newsImage3,
    },
    {
        date: "20 June, 2024",
        title: "Arca hosted students of the 115-th High School",
        description: "On December 8 students of the 115-th High School of Yerevan were hosted by Arca in...",
        imageUrl: newsImage4,
    },
];

function NewsCard({ date, title, description, imageUrl }) {
    return (
        <div className="news-card">
            <img src={imageUrl} alt={title} className="news-card_image" />
            <div className="news-card_content">
                <p className="news-card_date">{date}</p>
                <span className="news-card_title">{title}</span>
                <p className="news-card_description">{description}</p>
            </div>
        </div>
    );
}

function News() {
    return (
        <div className="news">
            <div className="news-header">
                <span>News of Arca</span>
                <div className="news-header_icons">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0.0164407 3.82196L0 12L3.99046 12L3.99046 3.99715C4.00629 3.99715 4.9794 4.00325 4.9794 4.0002L12 4.0002L12 -5.24537e-07L3.83761 -1.67747e-07C1.73064 -7.56487e-08 0.0219223 1.70984 0.0176598 3.82196"
                            fill="#243BE2"
                        />
                    </svg>
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M8.17804 11.9836L0 12L0 8.00954L8.00285 8.00954C8.00285 7.99371 7.99675 7.0206 7.9998 7.0206L7.9998 0H12L12 8.16239C12 10.2694 10.2902 11.9781 8.17804 11.9823"
                            fill="#ADF233"
                        />
                    </svg>
                </div>
            </div>
            <div className="news-title">
                <h1>News</h1>
            </div>

            <div className="news-cards">
                {newsData.map((item, index) => (
                    <NewsCard key={index} date={item.date} title={item.title} description={item.description} imageUrl={item.imageUrl} />
                ))}
            </div>
            <div className="news-footer">
                <Link to="/news" className="news-readmore">
                    <span> Read more</span>
                </Link>
            </div>
        </div>
    );
}

export default News;
