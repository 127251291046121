import React from "react";
import "./Brief.css";
import icon1 from "../../assets/about-icon_1.png";
import icon2 from "../../assets/about-icon_2.png";
import { useLanguage } from "../../LanguageContext";

function BriefCards() {
    const { language, overviewText } = useLanguage();
    const isArmenian = language === "ARM";


    const dynamicStyles = {
        fontFamily: language === "ARM" && "Mardoto",
        fontWeight: language === "ARM" && 500,
    };

    const cardsData = [
        {
            title: overviewText[language]?.[0]?.card1Title,
            stat: overviewText[language]?.[0]?.card1Number,
            description: overviewText[language]?.[0]?.card1Text,
        },
        {
            title: overviewText[language]?.[0]?.card2Title,
            description: overviewText[language]?.[0]?.card2Text,
            icon: icon1,
        },
        {
            title: overviewText[language]?.[0]?.card3Title,
            description: overviewText[language]?.[0]?.card3Text,
            icon: icon2,
        },
        {
            title: overviewText[language]?.[0]?.card4Title,
            stat: overviewText[language]?.[0]?.card4Number,
            description: overviewText[language]?.[0]?.card4Text,
        },
    ];

    return (
        <div className={`brief-cards-container ${isArmenian && "arm-language"}`}>
            {cardsData.map((card, index) => (
                <div key={index} className="brief-card">
                    <div className="brief-card-content">
                        <span style={dynamicStyles}>{card.title}</span>
                        {card.stat && (
                            <p className="brief-card-stat" style={dynamicStyles}>
                                {" "}
                                <svg width="43" height="44" viewBox="0 0 43 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M0.03125 25.8047V17.9102H17.248V0.525391H25.3945V17.9102H42.5273V25.8047H25.3945V43.1055H17.248V25.8047H0.03125Z"
                                        fill="black"
                                    />
                                </svg>
                                {card.stat}
                            </p>
                        )}
                        {card.icon && <img src={card.icon} alt={`${card.title} icon`} className="brief-card-icon" />}
                    </div>
                    <p className="brief-card_desc" style={dynamicStyles}>{card.description}</p>
                </div>
            ))}
        </div>
    );
}

function Brief() {
    const { language, overviewText } = useLanguage();

    const dynamicStyles = {
        fontFamily: language === "ARM" && "Mardoto",
        fontWeight: language === "ARM" && 500,
    };

    return (
        <div className="brief">
            <div className="brief-header">
                <span style={dynamicStyles}>{overviewText[language][0].overviewTitle}</span>
                <div className="brief-header_icons">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0.0164407 3.82196L0 12L3.99046 12L3.99046 3.99715C4.00629 3.99715 4.9794 4.00325 4.9794 4.0002L12 4.0002L12 -5.24537e-07L3.83761 -1.67747e-07C1.73064 -7.56487e-08 0.0219223 1.70984 0.0176598 3.82196"
                            fill="#243BE2"
                        />
                    </svg>
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M8.17804 11.9836L0 12L0 8.00954L8.00285 8.00954C8.00285 7.99371 7.99675 7.0206 7.9998 7.0206L7.9998 0H12L12 8.16239C12 10.2694 10.2902 11.9781 8.17804 11.9823"
                            fill="#ADF233"
                        />
                    </svg>
                </div>
            </div>
            <div className="brief-title">
                <h1 style={dynamicStyles}>{overviewText[language][0].overviewText}</h1>
            </div>
            <BriefCards />
        </div>
    );
}

export default Brief;
