// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#preloader {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100vw;
    height: 100vh;
    background: white;
    max-width: 1920px;
    margin: 0 auto;
}

.part {
    position: relative;
    transition: all 0.3s ease;
}

.logo {
    width: 0;
    height: max-content;
    overflow: hidden;
    transition: width 0.3s ease;
}

.part:nth-child(1) {
    right: -15px;
}

.part:nth-child(3) {
    left: -15px;
}

.d-none {
    display: none !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/Loader.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,iBAAiB;IACjB,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI,QAAQ;IACR,mBAAmB;IACnB,gBAAgB;IAChB,2BAA2B;AAC/B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,wBAAwB;AAC5B","sourcesContent":["#preloader {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    position: relative;\n    width: 100vw;\n    height: 100vh;\n    background: white;\n    max-width: 1920px;\n    margin: 0 auto;\n}\n\n.part {\n    position: relative;\n    transition: all 0.3s ease;\n}\n\n.logo {\n    width: 0;\n    height: max-content;\n    overflow: hidden;\n    transition: width 0.3s ease;\n}\n\n.part:nth-child(1) {\n    right: -15px;\n}\n\n.part:nth-child(3) {\n    left: -15px;\n}\n\n.d-none {\n    display: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
