// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
    background-color: white;
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
    overflow: hidden;
}

@media (max-width: 1920px) {
    .wrapper {
        border-top-left-radius: 5.2vw;
        border-top-right-radius: 5.2vw;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Wrapper.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,6BAA6B;IAC7B,8BAA8B;IAC9B,gBAAgB;AACpB;;AAEA;IACI;QACI,6BAA6B;QAC7B,8BAA8B;IAClC;AACJ","sourcesContent":[".wrapper {\n    background-color: white;\n    border-top-left-radius: 100px;\n    border-top-right-radius: 100px;\n    overflow: hidden;\n}\n\n@media (max-width: 1920px) {\n    .wrapper {\n        border-top-left-radius: 5.2vw;\n        border-top-right-radius: 5.2vw;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
