// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.linkcards-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1920px;
    margin: 90px 70px;
}

@media (min-width: 576px) and (max-width: 1920px) {
    .linkcards-container {
        max-width: 100vw;
        margin: 4.6vw 3.6vw;
    }
}

@media (max-width: 576px) {
    .linkcards-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 576px;
        margin: 12.13vw 0 24.27vw 0;
        padding: 0 4.85vw;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Services.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI;QACI,gBAAgB;QAChB,mBAAmB;IACvB;AACJ;;AAEA;IACI;QACI,aAAa;QACb,sBAAsB;QACtB,WAAW;QACX,gBAAgB;QAChB,2BAA2B;QAC3B,iBAAiB;IACrB;AACJ","sourcesContent":[".linkcards-container {\n    display: flex;\n    flex-direction: row;\n    width: 100%;\n    max-width: 1920px;\n    margin: 90px 70px;\n}\n\n@media (min-width: 576px) and (max-width: 1920px) {\n    .linkcards-container {\n        max-width: 100vw;\n        margin: 4.6vw 3.6vw;\n    }\n}\n\n@media (max-width: 576px) {\n    .linkcards-container {\n        display: flex;\n        flex-direction: column;\n        width: 100%;\n        max-width: 576px;\n        margin: 12.13vw 0 24.27vw 0;\n        padding: 0 4.85vw;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
