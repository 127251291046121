// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scrollbar-wrapper {
    position: fixed;
    top: 50%;
    right: 32px;
    transform: translateY(-50%);
    width: 6px;
    height: 190px;
    background-color: #000;
    border-radius: 3px;
    overflow: hidden;
    z-index: 998;
}

.scrollbar {
    position: absolute;
    width: 6px;
    min-height: 32px;
    background-color: #243be2;
    border-radius: 3px;
    cursor: grab;
    transition: background-color 0.2s ease;
}

.scrollbar:hover {
    background-color: #555;
}

@media (min-width: 576px) and (max-width: 1920px) {
    .scrollbar-wrapper {
        right: 1.6vw;
        width: 0.3vw;
        height: 9.8vw;
        border-radius: 0.1vw;
    }

    .scrollbar {
        width: 0.3vw;
        min-height: 1.6vw;
        border-radius: 0.1vw;
    }
}


@media (max-width: 576px) {
    .scrollbar-wrapper{
        display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/CustomScrollbar.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,QAAQ;IACR,WAAW;IACX,2BAA2B;IAC3B,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,gBAAgB;IAChB,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;IACZ,sCAAsC;AAC1C;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI;QACI,YAAY;QACZ,YAAY;QACZ,aAAa;QACb,oBAAoB;IACxB;;IAEA;QACI,YAAY;QACZ,iBAAiB;QACjB,oBAAoB;IACxB;AACJ;;;AAGA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":[".scrollbar-wrapper {\n    position: fixed;\n    top: 50%;\n    right: 32px;\n    transform: translateY(-50%);\n    width: 6px;\n    height: 190px;\n    background-color: #000;\n    border-radius: 3px;\n    overflow: hidden;\n    z-index: 998;\n}\n\n.scrollbar {\n    position: absolute;\n    width: 6px;\n    min-height: 32px;\n    background-color: #243be2;\n    border-radius: 3px;\n    cursor: grab;\n    transition: background-color 0.2s ease;\n}\n\n.scrollbar:hover {\n    background-color: #555;\n}\n\n@media (min-width: 576px) and (max-width: 1920px) {\n    .scrollbar-wrapper {\n        right: 1.6vw;\n        width: 0.3vw;\n        height: 9.8vw;\n        border-radius: 0.1vw;\n    }\n\n    .scrollbar {\n        width: 0.3vw;\n        min-height: 1.6vw;\n        border-radius: 0.1vw;\n    }\n}\n\n\n@media (max-width: 576px) {\n    .scrollbar-wrapper{\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
