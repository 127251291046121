import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import { LanguageProvider } from "./LanguageContext";
import CustomScrollbar from "./components/CustomScrollbar";
import Home from "./pages/Home";
import About from "./pages/About";
import Contacts from "./pages/Contacts";
import Services from "./pages/Services";
import News from "./pages/News";
import Careers from "./pages/Careers";
import Loader from "./components/Loader";
import Documents from "./pages/Documents";

function App() {
    // useEffect(() => {
    //     function zoomBody(screenWidth) {
    //         const body = document.getElementsByTagName('body')[0];

    //         const viewportWidth = window.innerWidth;
    //         const zoom = viewportWidth / screenWidth;

    //         body.style.zoom = zoom;
    //         body.style.MozTransform = `scale(${zoom})`;
    //         body.style.OTransform = `scale(${zoom})`;

    //     }

    //     const handleZoom = () => {
    //         const viewportWidth = window.innerWidth;

    //         if (viewportWidth >= 1921) {
    //             zoomBody(2200);
    //         } else {
    //             const body = document.getElementsByTagName('body')[0];
    //             body.style.zoom = '';
    //             body.style.MozTransform = '';
    //             body.style.OTransform = '';
    //         }
    //     };

    //     handleZoom();

    //     window.addEventListener('resize', handleZoom);

    //     return () => {
    //         window.removeEventListener('resize', handleZoom);
    //     };
    // }, []);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => setIsLoading(false), 3000);
        return () => clearTimeout(timer);
    }, []);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <LanguageProvider>
            <Router>
                <Navbar />
                <CustomScrollbar />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/services" element={<Services />} />
                    <Route path="/news" element={<News />} />
                    <Route path="/careers" element={<Careers />} />
                    <Route path="/contacts" element={<Contacts />} />
                    <Route path="/documents" element={<Documents />} />
                </Routes>
            </Router>
        </LanguageProvider>
    );
}

export default App;
