// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.responsive-text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 36px;
    font-weight: 500;
}

@media (max-width: 576px) {
    .responsive-text {
        font-size: 5.5vw;
    }
}

.responsive-highlight-green {
    color: rgb(173, 242, 51);
}

.responsive-highlight-blue {
    color: rgb(36, 59, 226);
}`, "",{"version":3,"sources":["webpack://./src/pages/News.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI;QACI,gBAAgB;IACpB;AACJ;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":[".responsive-text {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n    font-size: 36px;\n    font-weight: 500;\n}\n\n@media (max-width: 576px) {\n    .responsive-text {\n        font-size: 5.5vw;\n    }\n}\n\n.responsive-highlight-green {\n    color: rgb(173, 242, 51);\n}\n\n.responsive-highlight-blue {\n    color: rgb(36, 59, 226);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
