import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./Share.css";
import cbLogo from "../../assets/cbLogo.png";
import acbaLogo from "../../assets/acba.png";
import ameriaLogo from "../../assets/ameria.png";
import amioLogo from "../../assets/amio.png";
import araratLogo from "../../assets/ararat.png";
import ardshinLogo from "../../assets/ardshin.png";
import armeconomLogo from "../../assets/aeb.png";
import armswissLogo from "../../assets/armswiss.png";
import artsakhLogo from "../../assets/artsakh.png";
import byblosLogo from "../../assets/biblos.png";
import converseLogo from "../../assets/converse.png";
import evocaLogo from "../../assets/evoca.png";
import fastLogo from "../../assets/fast.png";
import hsbcLogo from "../../assets/hsbc.png";
import idLogo from "../../assets/id.png";
import uniLogo from "../../assets/uni.png";
import inecoLogo from "../../assets/ineco.png";
import { useLanguage } from "../../LanguageContext";

function Share() {
    const { language, shareholderText } = useLanguage();

    const shareholders = [
        { logo: cbLogo, name: shareholderText[language]?.[0]?.shareholder1, description: shareholderText[language]?.[0]?.description1 },
        { logo: acbaLogo, name: shareholderText[language]?.[0]?.shareholder2, description: shareholderText[language]?.[0]?.description2 },
        { logo: ameriaLogo, name: shareholderText[language]?.[0]?.shareholder3, description: shareholderText[language]?.[0]?.description3 },
        { logo: amioLogo, name: shareholderText[language]?.[0]?.shareholder4, description: shareholderText[language]?.[0]?.description4 },
        { logo: araratLogo, name: shareholderText[language]?.[0]?.shareholder5, description: shareholderText[language]?.[0]?.description5 },
        { logo: ardshinLogo, name: shareholderText[language]?.[0]?.shareholder6, description: shareholderText[language]?.[0]?.description6 },
        { logo: ardshinLogo, name: shareholderText[language]?.[0]?.shareholder18, description: shareholderText[language]?.[0]?.description18 },
        { logo: armswissLogo, name: shareholderText[language]?.[0]?.shareholder8, description: shareholderText[language]?.[0]?.description8 },
        { logo: artsakhLogo, name: shareholderText[language]?.[0]?.shareholder9, description: shareholderText[language]?.[0]?.description9 },
        { logo: idLogo, name: shareholderText[language]?.[0]?.shareholder15, description: shareholderText[language]?.[0]?.description15 },
        { logo: byblosLogo, name: shareholderText[language]?.[0]?.shareholder10, description: shareholderText[language]?.[0]?.description10 },
        { logo: evocaLogo, name: shareholderText[language]?.[0]?.shareholder12, description: shareholderText[language]?.[0]?.description12 },
        { logo: uniLogo, name: shareholderText[language]?.[0]?.shareholder16, description: shareholderText[language]?.[0]?.description16 },
        { logo: inecoLogo, name: shareholderText[language]?.[0]?.shareholder17, description: shareholderText[language]?.[0]?.description17 },
        { logo: converseLogo, name: shareholderText[language]?.[0]?.shareholder11, description: shareholderText[language]?.[0]?.description11 },
        { logo: armeconomLogo, name: shareholderText[language]?.[0]?.shareholder7, description: shareholderText[language]?.[0]?.description7 },
        { logo: fastLogo, name: shareholderText[language]?.[0]?.shareholder13, description: shareholderText[language]?.[0]?.description13 },
    ];

    const chunkArray = (array, size) => {
        const result = [];
        for (let i = 0; i < array.length; i += size) {
            result.push(array.slice(i, i + size));
        }
        return result;
    };

    const groupedShareholders = chunkArray(shareholders, 6);

    const dynamicStyles = {
        fontFamily: language === "ARM" && "Mardoto",
        fontWeight: language === "ARM" && 500,
    };

    return (
        <div className="share">
            <div className="share-header">
                <span style={dynamicStyles}>{shareholderText[language]?.[0]?.shareholderTitle}</span>
                <div className="share-header_icons">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0.0164407 3.82196L0 12L3.99046 12L3.99046 3.99715C4.00629 3.99715 4.9794 4.00325 4.9794 4.0002L12 4.0002L12 -5.24537e-07L3.83761 -1.67747e-07C1.73064 -7.56487e-08 0.0219223 1.70984 0.0176598 3.82196"
                            fill="#243BE2"
                        />
                    </svg>
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M8.17804 11.9836L0 12L0 8.00954L8.00285 8.00954C8.00285 7.99371 7.99675 7.0206 7.9998 7.0206L7.9998 0H12L12 8.16239C12 10.2694 10.2902 11.9781 8.17804 11.9823"
                            fill="#ADF233"
                        />
                    </svg>
                </div>
            </div>
            <div className="share-title">
                <h1 style={dynamicStyles}>{shareholderText[language]?.[0]?.shareholderTitle2}</h1>
            </div>
            <div className="share-pagination_container"></div>
            <div className="share-slider">
                <Swiper
                    breakpoints={{
                        1200: {
                            spaceBetween: 20,
                            slidesPerView: 6,
                            slidesPerGroup: 6,
                        },
                        576: {
                            spaceBetween: 10,
                            slidesPerView: 6,
                            slidesPerGroup: 6,
                        },
                    }}
                    spaceBetween={25}
                    slidesPerView={6}
                    slidesPerGroup={6}
                    pagination={{
                        clickable: true,
                        el: ".share-pagination_container",
                    }}
                    modules={[Pagination]}
                >
                    {shareholders.map((shareholder, groupIndex) => (
                        <SwiperSlide key={groupIndex}>
                            <div className="shareholder-group">
                                <div className="shareholder-card" key={groupIndex}>
                                    <div className="shareholder-card_img">
                                        <img src={shareholder.logo} alt={`${shareholder.name} logo`} />
                                    </div>
                                    <div className="shareholder-card_desc">
                                        <h3 style={dynamicStyles}>{shareholder.name}</h3>
                                        <p style={dynamicStyles}>{shareholder.description}</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            {/* Custom Navigation Buttons */}
            <div className="share-navigation">
                <div className="share-control">
                    <button className="share-prev">
                        <div className="svg-wrapper">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 17L1 9M1 9L9 1M1 9L17 9" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                        <div className="svg-wrapper">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 17L1 9M1 9L9 1M1 9L17 9" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </button>
                    <button className="share-next">
                        <div className="svg-wrapper">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 17L17 9M17 9L9 1M17 9L1 9" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                        <div className="svg-wrapper">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 17L17 9M17 9L9 1M17 9L1 9" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </button>
                </div>
            </div>
            <div className="share-slider_mobile">
                <Swiper
                    spaceBetween={20}
                    slidesPerView={1.3}
                    navigation={{
                        prevEl: ".share-prev",
                        nextEl: ".share-next",
                    }}
                    loop={true}
                    modules={[Navigation]}
                    className="mySwiperShare"
                >
                    {shareholders.map((shareholder, index) => (
                        <SwiperSlide key={index}>
                            <div className="shareholder-group">
                                <div className="shareholder-card">
                                    <div className="shareholder-card_img">
                                        <img src={shareholder.logo} alt={`${shareholder.name} logo`} />
                                    </div>
                                    <div className="shareholder-card_desc">
                                        <h3 style={dynamicStyles}>{shareholder.name}</h3>
                                        <p style={dynamicStyles}>{shareholder.description}</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
}

export default Share;
