import React, { useState } from "react";
import "./Circle.css";
import { useLanguage } from "../../LanguageContext";

const Circle = () => {
    const { language, paymentText, missionVissionNumbers } = useLanguage();
    const isArmenian = language === "ARM";

    const [activeSection, setActiveSection] = useState(0);
    const [rotationAngle, setRotationAngle] = useState(0);

    const sections = [
        {
            name: paymentText[language]?.[1]?.about,
            content: paymentText[language]?.[1]?.aboutText,
        },
        {
            name: paymentText[language]?.[1]?.mission,
            content: paymentText[language]?.[1]?.missionText,
        },
        {
            name: paymentText[language]?.[1]?.achivement,
            content: paymentText[language]?.[1]?.achivementText,
        },
        {
            name: paymentText[language]?.[1]?.benefit,
            content: paymentText[language]?.[1]?.benefitText,
        },
    ];

    const handleSectionClick = (index) => {
        const sectionsCount = sections.length;
        const forwardSteps = (index - activeSection + sectionsCount) % sectionsCount;
        const backwardSteps = (activeSection - index + sectionsCount) % sectionsCount;

        const shortestSteps = forwardSteps <= backwardSteps ? forwardSteps : -backwardSteps;
        const angleChange = shortestSteps * 90;

        setActiveSection(index);
        setRotationAngle((prevAngle) => prevAngle + angleChange);
    };

    const handleNext = () => {
        const nextIndex = (activeSection + 1) % sections.length;
        handleSectionClick(nextIndex);
    };

    const handlePrevious = () => {
        const prevIndex = (activeSection - 1 + sections.length) % sections.length;
        handleSectionClick(prevIndex);
    };

    const dynamicStyles = {
        fontFamily: language === "ARM" && "Mardoto",
        fontWeight: language === "ARM" && 500,
    };

    return (
        <div className={`payment-system ${isArmenian ? "arm-language" : ""}`}>
            <div className="payment-system-cards">
                <div className="payment-system-cards-header">
                    <span style={dynamicStyles}>{paymentText[language][0].paymentTitle}</span>
                    <div className="payment-system-cards-header_icons">
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.0164407 3.82196L0 12L3.99046 12L3.99046 3.99715C4.00629 3.99715 4.9794 4.00325 4.9794 4.0002L12 4.0002L12 -5.24537e-07L3.83761 -1.67747e-07C1.73064 -7.56487e-08 0.0219223 1.70984 0.0176598 3.82196"
                                fill="#243BE2"
                            />
                        </svg>

                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M8.17804 11.9836L0 12L0 8.00954L8.00285 8.00954C8.00285 7.99371 7.99675 7.0206 7.9998 7.0206L7.9998 0H12L12 8.16239C12 10.2694 10.2902 11.9781 8.17804 11.9823"
                                fill="#ADF233"
                            />
                        </svg>
                    </div>
                </div>
                <div className="payment-system-cards-title">
                    <h1 style={dynamicStyles}>{paymentText[language][0].paymentText}</h1>
                </div>
            </div>
            {/* main block */}
            <div className="circle-wrapper">
                <div className="content">
                    <div className="text-wrapper-name">
                        <span style={dynamicStyles} className={activeSection === 0 ? "fade-in" : "fade-out"}>
                            {sections[0].name}
                        </span>
                        <span style={dynamicStyles} className={activeSection === 1 ? "fade-in" : "fade-out"}>
                            {sections[1].name}
                        </span>
                        <span style={dynamicStyles} className={activeSection === 2 ? "fade-in" : "fade-out"}>
                            {sections[2].name}
                        </span>
                        <span style={dynamicStyles} className={activeSection === 3 ? "fade-in" : "fade-out"}>
                            {sections[3].name}
                        </span>
                    </div>

                    <div className="text-wrapper">
                        <span style={dynamicStyles} className={activeSection === 0 ? "fade-in" : "fade-out"}>
                            {sections[0].content}
                        </span>
                        <span style={dynamicStyles} className={activeSection === 1 ? "fade-in" : "fade-out"}>
                            {sections[1].content}
                        </span>
                        <span style={dynamicStyles} className={activeSection === 2 ? "fade-in" : "fade-out"}>
                            {sections[2].content}
                        </span>
                        <span style={dynamicStyles} className={activeSection === 3 ? "fade-in" : "fade-out"}>
                            {sections[3].content}
                        </span>
                    </div>

                    <div className="circle-container circle-container-mobile">
                        <div className="circle-content">
                            {sections.map((section, index) => (
                                <p
                                    key={index}
                                    className={`section ${activeSection === index ? "active" : ""}`}
                                    style={{
                                        ...(index === 0
                                            ? { top: "-60px" }
                                            : index === 1
                                            ? { right: "-130px" }
                                            : index === 2
                                            ? { bottom: "-60px" }
                                            : { left: "-130px" }),
                                        ...dynamicStyles,
                                    }}
                                    onClick={() => handleSectionClick(index)}
                                >
                                    {section.name}
                                </p>
                            ))}
                            <div
                                className="circle-rotation-wrapper"
                                style={{
                                    transform: `rotate(${rotationAngle}deg)`,
                                    background: `linear-gradient(180deg, #243BE2 0%, #020201 100%)`,
                                }}
                            >
                                <div className="circle-border">
                                    <svg width="40" height="24" viewBox="0 0 40 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M14.0373 3.10677L1 16.0918L7.34878 22.4406L20.0812 9.70813C20.1064 9.73332 21.6449 11.2912 21.6498 11.2864L32.8195 22.4561L39.1838 16.0918L26.1975 3.10552C22.8453 -0.246654 17.4064 -0.244873 14.0393 3.1087L14.0373 3.10677Z"
                                            fill="#243BE2"
                                            stroke="#243BE2"
                                            strokeWidth="0.83"
                                            strokeMiterlimit="10"
                                        />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="content_desc">
                        <div className={`content_item ${activeSection === 0 ? "fade-in" : "fade-out"}`}>
                            <h1 style={dynamicStyles}>{missionVissionNumbers[language].aboutusNumber}</h1>
                            <p style={dynamicStyles}>{missionVissionNumbers[language].aboutus}</p>
                        </div>
                        <div className={`content_item ${activeSection === 1 ? "fade-in" : "fade-out"}`}>
                            <h1 style={dynamicStyles}>{missionVissionNumbers[language].missionNumber}</h1>
                            <p style={dynamicStyles}>{missionVissionNumbers[language].mission}</p>
                        </div>
                        <div className={`content_item ${activeSection === 2 ? "fade-in" : "fade-out"}`}>
                            <h1 style={dynamicStyles}>{missionVissionNumbers[language].vissionNumber}</h1>
                            <p style={dynamicStyles}>{missionVissionNumbers[language].vission}</p>
                        </div>
                        <div className={`content_item ${activeSection === 3 ? "fade-in" : "fade-out"}`}>
                            <h1 style={dynamicStyles}>{missionVissionNumbers[language].valuesNumber}</h1>
                            <p style={dynamicStyles}>{missionVissionNumbers[language].values}</p>
                        </div>
                    </div>
                </div>

                <div className="circle-container circle-container-desktop">
                    <div className="circle-content">
                        {sections.map((section, index) => (
                            <p
                                key={index}
                                className={`section ${activeSection === index ? "active" : ""}`}
                                style={{
                                    ...(index === 0
                                        ? { top: "-90px" }
                                        : index === 1
                                        ? { right: "-160px" }
                                        : index === 2
                                        ? { bottom: "-90px" }
                                        : { left: "-160px" }),
                                    ...dynamicStyles,
                                }}
                                onClick={() => handleSectionClick(index)}
                            >
                                {section.name}
                            </p>
                        ))}
                        <div
                            className="circle-rotation-wrapper"
                            style={{
                                transform: `rotate(${rotationAngle}deg)`,
                                background: `linear-gradient(180deg, #243BE2 0%, #020201 100%)`,
                            }}
                        >
                            <div className="circle-border">
                                <svg width="40" height="24" viewBox="0 0 40 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M14.0373 3.10677L1 16.0918L7.34878 22.4406L20.0812 9.70813C20.1064 9.73332 21.6449 11.2912 21.6498 11.2864L32.8195 22.4561L39.1838 16.0918L26.1975 3.10552C22.8453 -0.246654 17.4064 -0.244873 14.0393 3.1087L14.0373 3.10677Z"
                                        fill="#243BE2"
                                        stroke="#243BE2"
                                        strokeWidth="0.83"
                                        strokeMiterlimit="10"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="arrow-controls">
                    <button className="arrow left-arrow" onClick={handlePrevious}>
                        <div className="svg-wrapper">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 17L1 9M1 9L9 1M1 9L17 9" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                        <div className="svg-wrapper">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 17L1 9M1 9L9 1M1 9L17 9" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </button>
                    <button className="arrow right-arrow" onClick={handleNext}>
                        <div className="svg-wrapper">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 17L17 9M17 9L9 1M17 9L1 9" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                        <div className="svg-wrapper">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 17L17 9M17 9L9 1M17 9L1 9" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </button>
                </div>
            </div>

            {/* footer */}

            <div className="payment-system_footer">
                <span style={dynamicStyles}> {paymentText[language][0].paymentFooter}</span>
            </div>
        </div>
    );
};

export default Circle;
