import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import "./CustomScrollbar.css";

const CustomScrollbar = () => {
    const [scrollTop, setScrollTop] = useState(0);
    const isDragging = useRef(false);
    const scrollbarRef = useRef(null);
    const wrapperRef = useRef(null);
    const location = useLocation(); // Access current route location

    useEffect(() => {
        // Reset scroll position on route change
        const handleRouteChange = () => {
            window.scrollTo(0, 0); // Reset the window scroll position
            setScrollTop(0); // Reset custom scrollbar position
        };

        handleRouteChange(); // Execute on route change
    }, [location]); // Listen to route changes

    useEffect(() => {
        const handleScroll = () => {
            const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
            const maxScrollTop = scrollHeight - clientHeight;
            const scrollProgress = (scrollTop / maxScrollTop) * 100;

            // Calculate the top position in the wrapper (keeping it within bounds)
            const wrapperHeight = wrapperRef.current.clientHeight;
            const scrollbarHeight = scrollbarRef.current.clientHeight;
            const maxWrapperTop = wrapperHeight - scrollbarHeight;
            const wrapperTop = (scrollProgress / 100) * maxWrapperTop;

            setScrollTop(wrapperTop);
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const handleMouseDown = () => {
        isDragging.current = true;
    };

    const handleMouseMove = (e) => {
        if (!isDragging.current) return;

        const wrapperRect = wrapperRef.current.getBoundingClientRect();
        const scrollbarHeight = scrollbarRef.current.clientHeight;

        // Calculate new scrollbar position based on drag, keeping within bounds
        let newTop = e.clientY - wrapperRect.top - scrollbarHeight / 2;
        const maxTop = wrapperRect.height - scrollbarHeight;

        // Constrain newTop to be within bounds of the wrapper
        newTop = Math.max(0, Math.min(newTop, maxTop));

        // Calculate the document scroll position based on the scrollbar's new top position
        const { scrollHeight, clientHeight } = document.documentElement;
        const maxScrollTop = scrollHeight - clientHeight;
        const scrollProgress = (newTop / maxTop) * maxScrollTop;

        // Scroll the document to the new calculated position
        window.scrollTo(0, scrollProgress);
    };

    const handleMouseUp = () => {
        isDragging.current = false;
    };

    useEffect(() => {
        window.addEventListener("mousemove", handleMouseMove);
        window.addEventListener("mouseup", handleMouseUp);
        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
            window.removeEventListener("mouseup", handleMouseUp);
        };
    }, []);

    return (
        <div className="scrollbar-wrapper" ref={wrapperRef}>
            <div
                className="scrollbar"
                ref={scrollbarRef}
                style={{ top: `${scrollTop}px` }}
                onMouseDown={handleMouseDown}
            ></div>
        </div>
    );
};

export default CustomScrollbar;