import React, { useEffect, useState } from "react";
import "./Loader.css";

const Loader = () => {
    const [logoWidth, setLogoWidth] = useState(10);
    const [partSize, setPartSize] = useState(48);
    const [positionOffset, setPositionOffset] = useState(0);
    const [isRotated, setIsRotated] = useState(false);

    useEffect(() => {
        let intervalId;

        const startAnimation = (index, index1, index2, rotated = false) => {
            intervalId = setInterval(() => {
                index += 10;
                index1--;
                index2 -= 2;

                if (index < 150) {
                    updateStyles(index, index1, index2, rotated);
                } else {
                    clearInterval(intervalId);
                    setTimeout(() => {
                        reverseAnimation(index, index1 + 1, index2 - 2, rotated);
                    }, 500);
                }
            }, 26);
        };

        const reverseAnimation = (index, index1, index2, rotated = false) => {
            const intervalIdReverse = setInterval(() => {
                index -= 10;
                index1++;
                index2 += 2;

                if (index >= 0 && index < 150) {
                    updateStyles(index, index1, index2, rotated);
                } else {
                    clearInterval(intervalIdReverse);
                    setTimeout(() => {
                        startAnimation(10, 48, 0, !rotated);
                    }, 500);
                }
            }, 26);
        };

        const updateStyles = (logoWidth, partSize, positionOffset, rotated) => {
            setLogoWidth(logoWidth);
            setPartSize(partSize);
            setPositionOffset(positionOffset);
            setIsRotated(rotated);
        };

        startAnimation(10, 48, 0);

        return () => clearInterval(intervalId); // Cleanup on unmount
    }, []);

    return (
        <div id="preloader">
            <svg
                className="part part-1"
                style={{
                    width: `${partSize}px`,
                    height: `${partSize}px`,
                    top: isRotated ? "" : `${positionOffset}px`,
                    bottom: isRotated ? `${positionOffset}px` : "",
                    transform: `rotate(${isRotated ? -90 : 0}deg)`,
                }}
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M0.0164407 3.82196L0 12L3.99046 12L3.99046 3.99715C4.00629 3.99715 4.9794 4.00325 4.9794 4.0002L12 4.0002L12 -5.24537e-07L3.83761 -1.67747e-07C1.73064 -7.56487e-08 0.0219223 1.70984 0.0176598 3.82196"
                    fill="#243BE2"
                />
            </svg>
            <svg
                className="logo"
                style={{ width: `${logoWidth}px` }}
                width="119"
                height="30"
                viewBox="0 0 119 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M0.956505 0.0987031V9.74558H18.7238C15.5803 10.0658 14.6703 10.2579 11.927 10.9224C11.6868 10.9811 9.7948 11.5309 9.5653 11.6029C-0.61261 14.8719 0.0171841 20.7214 0.0038413 22.0023C-0.00683297 23.1231 1.16735 29.8105 8.7274 30C12.909 29.8372 14.8784 27.3261 15.8231 25.7169C12.9891 27.2674 10.2832 25.4207 9.50393 23.8223C7.79338 20.3211 10.3259 16.7452 13.6269 14.6371C17.4723 12.1766 20.2182 12.1393 20.2449 12.1393V29.1114H29.8918V9.37731C29.8918 4.28569 25.7688 0.152084 20.6745 0.141409L0.951195 0.101374L0.956505 0.0987031Z"
                    fill="#243BE2"
                />
                <path
                    d="M89.2114 0.0987031V9.74558H106.979C103.835 10.0658 102.925 10.2579 100.182 10.9224C99.9417 10.9811 98.0497 11.5309 97.8202 11.6029C87.6423 14.8719 88.2721 20.7214 88.2614 22.0023C88.2507 23.1231 89.4249 29.8105 96.985 30C101.167 29.8372 103.136 27.3261 104.081 25.7169C101.249 27.2674 98.5407 25.4207 97.7615 23.8223C96.0509 20.3211 98.5834 16.7452 101.884 14.6371C105.73 12.1766 108.476 12.1393 108.502 12.1393V29.1114H118.149V9.37731C118.149 4.28569 114.026 0.152084 108.932 0.141409L89.2088 0.101374L89.2114 0.0987031Z"
                    fill="#ADF233"
                />
                <path
                    d="M47.4136 2.93806C45.9939 4.83274 45.132 7.73347 44.8304 11.6429H44.5075V0.0987031H34.842V28.9779H44.5075V17.2576C44.5075 12.681 46.7438 9.65484 51.219 9.65484H59.0886V0.0987031H53.1537C50.744 0.0987031 48.8306 1.04605 47.4109 2.93806"
                    fill="#243BE2"
                />
                <path
                    d="M70.4513 14.5197C70.4513 17.2043 72.6288 19.3818 75.3134 19.3818H84.8749V29.0367H75.0759C67.1903 29.0367 60.7964 22.6428 60.7964 14.7572V14.2795C60.7964 6.39389 67.1903 0 75.0759 0H84.8749V9.65487H75.3134C72.6288 9.65487 70.4513 11.8324 70.4513 14.517"
                    fill="#243BE2"
                />
            </svg>
            <svg
                className="part part-2"
                style={{
                    width: `${partSize}px`,
                    height: `${partSize}px`,
                    top: isRotated ? `${positionOffset}px` : "",
                    bottom: isRotated ? "" : `${positionOffset}px`,
                    transform: `rotate(${isRotated ? -90 : 0}deg)`,
                }}
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M8.17804 11.9836L0 12L0 8.00954L8.00285 8.00954C8.00285 7.99371 7.99675 7.0206 7.9998 7.0206L7.9998 0H12L12 8.16239C12 10.2694 10.2902 11.9781 8.17804 11.9823"
                    fill="#ADF233"
                />
            </svg>
        </div>
    );
};

export default Loader;
