import React, {useState, useEffect} from "react";
import { useLanguage } from "../LanguageContext";
import MainBlock from "../components/MainBlock";
import ServicesBlock from "../components/home/ServicesBlock";
import Footer from "../components/Footer";
import News from "../components/home/News";
import Wrapper from "../components/Wrapper";
import Circle from "../components/home/Circle";
import bgGif from "../assets/bg.gif";
import Numbers from "../components/home/Numbers";

const Home = () => {
    const { language, textContent } = useLanguage();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 576);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const paymentTitle = isMobile
        ? textContent[language].paymentInnovationsMobile
        : textContent[language].paymentInnovations;

    return (
        <>
            <MainBlock title={paymentTitle} gifSrc={bgGif} />
            <Wrapper>
                <Numbers />
                <Circle />
                <ServicesBlock />
                {/* <News /> */}
                <Footer />
            </Wrapper>
        </>
    );
};

export default Home;
