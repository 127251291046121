import React, { useEffect, useState } from "react";
import "./Numbers.css";
import cubeImg from "../../assets/cube.gif";
import { useLanguage } from "../../LanguageContext";

function Numbers() {
    const { language, numberText, numberHeader } = useLanguage();
    const [inView, setInView] = useState(false);
    const isArmenian = language === "ARM";

    useEffect(() => {
        const handleScroll = () => {
            const element = document.querySelector(".numbers");
            const rect = element.getBoundingClientRect();
            const isVisible = rect.top <= window.innerHeight && rect.bottom >= 0;
            if (isVisible) {
                setInView(true);
                window.removeEventListener("scroll", handleScroll);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const renderNumber = (number, delay) => {
        const digits = number.toString().split("");
        return digits.map((digit, index) => (
            <span key={index} style={{ animationDelay: `${delay + index * 0.2}s` }} className={inView ? "digit animate" : "digit"}>
                {digit}
            </span>
        ));
    };

    const dynamicStyles = {
        fontFamily: language === "ARM" && "Mardoto",
        fontWeight: language === "ARM" && 500,
    };

    return (
        <div className={`numbers ${isArmenian && "arm-language"}`}>
            <div className="numbers_head">
                <h1 style={dynamicStyles}>{numberHeader[language].title}</h1>
                <img src={cubeImg} alt="Cube" />
            </div>
            <div className="numbers_container">
                <div className="number_item">
                    <h1>{renderNumber(numberText[language][0].number, 0)}</h1>
                    <p style={dynamicStyles}>{numberText[language][0].text}</p>
                </div>
                <div className="number_item">
                    <h1>{renderNumber(numberText[language][1].number, 0.1)}</h1>
                    <p style={dynamicStyles}>{numberText[language][1].text}</p>
                </div>
                <div className="number_item">
                    <h1>{renderNumber(numberText[language][2].number, 0.2)}</h1>
                    <p style={dynamicStyles}>{numberText[language][2].text}</p>
                </div>
            </div>
        </div>
    );
}

export default Numbers;
